<template>
  <div class="page">
    <div class="top_box">
      <img src="./img/topPic.png" alt="" />
    </div>
    <!-- 工具 -->
    <div class="tool_box">
      <div class="item" @click="toPath('maintenanceFeedback')">
        <img src="./img/camera.png" alt="" />
        <div class="name">报修上报</div>
      </div>
      <div class="item">
        <img src="./img/report.png" @click="toPath('myMaintenanceReport')" alt="" />
        <div class="name">我的上报</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "clapCasually",
  data() {
    return {};
  },
  computed: {},
  created() {},
  async mounted() {},
  methods: {
    toPath(name) {
      this.$router.push({
        name,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  min-height: 100vh;
  background: #fafafa;
  padding-bottom: 30px;

  .list {
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
    .box {
      width: 100%;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
      border-radius: 16px;
      padding: 0 32px 0 38px;

      .item {
        box-sizing: border-box;
        width: 100%;
        height: 212px;
        border-bottom: 2px solid rgba(0, 0, 0, 0.06);
        display: flex;
        padding: 36px 0 32px;

        .status_box {
          flex: 1;
          height: 100%;

          p {
            font-size: 26px;
            color: rgba(0, 0, 0, 0.5);
            text-align: right;

            span {
              width: 10px;
              height: 10px;

              border-radius: 50%;
              display: inline-block;
              position: relative;
              top: -10px;
              right: -2px;
            }

            .green {
              background: #66b95a;
            }

            .blue {
              background: #8db0e8;
            }

            .red {
              background: #c33333;
            }

            .yellow {
              background: #f9980b;
            }
          }
        }

        .detail_box {
          box-sizing: border-box;
          width: 330px;
          height: 100%;
          padding-left: 14px;

          .name {
            margin-top: 4px;
            font-size: 34px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .type {
            font-size: 24px;
            color: rgba(0, 0, 0, 0.85);
          }

          .time {
            margin-top: 32px;
            font-size: 24px;
            color: rgba(0, 0, 0, 0.41);
          }
        }

        .img_box {
          width: 144px;
          height: 100%;
          border-radius: 10px;
          overflow: hidden;

          img {
            width: 144px;
            height: 144px;
          }
        }
      }

      .item:nth-last-child(1) {
        border: none;
      }
    }
  }

  .message_box {
    margin-top: 22px;
    width: 100%;
    height: 162px;
    background: #ffffff;
    font-size: 24px;
    color: #797d7f;
    box-sizing: border-box;
    padding: 32px 50px 0;
    margin-bottom: 22px;
  }

  .tool_box {
    width: 100%;
    height: 204px;
    margin-top: -128px;
    z-index: 2;
    position: relative;
    box-sizing: border-box;
    padding: 0 30px;
    display: flex;
    justify-content: space-between;

    .item {
      height: 100%;
      width: 330px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
      border-radius: 16px;
      display: flex;
      align-items: center;

      .name {
        font-size: 36px;
        font-weight: 600;
        color: #0f0e47;
        margin-left: 20px;
      }

      img {
        width: 120px;
        height: 120px;
        margin-left: 26px;
      }
    }
  }

  .top_box {
    width: 100%;
    height: 600px;
    z-index: -1 !important;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
